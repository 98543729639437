import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Finally understanding Node.js internals.",
  "author": "Patrick Passarella",
  "date": "2021-04-30",
  "subtitle": "A straightforward post about some of the Node.js internals aspects, like V8, Event Loop, JIT, Libuv, and more bonus topics.",
  "cover": "./cover.jpg",
  "coverCredit": "@tomadamsmia",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Scope`}</h1>
    <p><strong parentName="p">{`First of all, what does `}<em parentName="strong">{`node internals`}</em>{` means?`}</strong>{`
Node internals is the structure of Node, how does it work, why it works like that, what technologies are involved, things like that.`}</p>
    <p>{`In this post, I will talk about some of the core technologies Node is composed, a more top-down perspective on how they work, and some additional info that may help you understand some more about it.`}</p>
    <p><strong parentName="p">{`More specifically:`}</strong></p>
    <ul>
      <li parentName="ul">{`V8 Engine`}</li>
      <li parentName="ul">{`JIT Paradigm`}</li>
      <li parentName="ul">{`Non-blocking I/O`}</li>
      <li parentName="ul">{`Libuv`}</li>
      <li parentName="ul">{`Event Loop`}</li>
      <li parentName="ul">{`Generators`}</li>
      <li parentName="ul">{`Async Await`}</li>
    </ul>
    <p>{`This is not an advanced post, actually, it's the other way around, a post for you to finally understand the basics of how Node works, because there's too much about it.`}</p>
    <h1>{`Why`}</h1>
    <p>{`Why learn node internals?
First, knowledge is always welcomed, especially if it will make a big change in your technical thinking skills, and this is the case. Second, understanding how things work, will make you develop better, and find and fix bugs easier.`}</p>
    <h1>{`V8 Engine`}</h1>
    <p>{`We need to start talking about V8, because almost everything is related to it.`}</p>
    <p>{`V8 is an engine created by Google, written in C++, that compiles JavaScript source code to native machine code at runtime. It was created to optimize javascript execution inside browsers.`}</p>
    <p>{`Javascript is an "interpreted" language, but the v8 `}<strong parentName="p">{`compiles`}</strong>{` the code and optimizes the execution, allowing the execution to be done on top of the compiled code.`}</p>
    <p><strong parentName="p">{`Javascript execution in V8 is divided into three stages:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Source to syntax tree:`}</strong>{` The parser generates an abstract syntax tree (AST) from the source code;`}</li>
      <li parentName="ul"><strong parentName="li">{`Syntax tree to bytecode:`}</strong>{` V8 `}<em parentName="li">{`Ignition`}</em>{` generates bytecode from the syntax tree;`}</li>
      <li parentName="ul"><strong parentName="li">{`Bytecode to machine code:`}</strong>{` V8 `}<em parentName="li">{`TurboFan`}</em>{` generates a graph from bytecode, replacing sections of bytecode with machine code.`}</li>
    </ul>
    <p>{`These two last stages operate within the just-in-time (JIT) paradigm.`}</p>
    <h1>{`JIT paradigm`}</h1>
    <p>{`To execute any program, the computer must translate the code to machine language. There are a few ways to do that.`}</p>
    <h3>{`Interpreter:`}</h3>
    <p>{`Translates and then executes, line by line. Has fast translation (easy) and slow execution;`}</p>
    <h3>{`Compiler:`}</h3>
    <p>{`Compile all the code to machine code at once before executing it. Slow translation (complex), but fast execution.`}</p>
    <h3>{`JIT (Just in Time) compilation:`}</h3>
    <p>{`Compilation at run time. Combine the best parts from both interpreter and compiler, making translation and execution fast.
The code is run through an interpreter (Ignition), and during execution, it keeps track of code segments that can be reused where possible, and others that can be used to make optimizations based on assumptions (TurboFan).`}</p>
    <p>{`The main problem the interpreter have is that if you pass the same line of code 10 times, it will translate 10 times. JIT tries to avoid retranslations.`}</p>
    <p>{`The JIT compiler can expose overhead memory consumption. `}<strong parentName="p">{`Ignition`}</strong>{` addresses this by achieving three objectives: reducing memory usage, reducing startup time, and reducing complexity. All three objectives are accomplished by compiling AST to bytecode and collecting feedback during program execution.`}</p>
    <h1>{`Threads`}</h1>
    <p>{`Some info about threads, which is commonly confused by people.`}</p>
    <p>{`A thread is something that is associated with an instance of each program we run, it's an operation that our CPU needs to perform.`}</p>
    <p>{`There are two types of threads.`}</p>
    <h3>{`Single Thread`}</h3>
    <p>{`Only one piece of code can be executed at a time.
For instance, if multiple requests are sent to a server, the next one needs to wait for the current one to send back a response to be processed.`}</p>
    <h3>{`Multi Thread`}</h3>
    <p>{`Multiple pieces of code can be executed at the same time, in parallel.
Each request sent to a server simultaneously will create a new thread to process it.`}</p>
    <p>{`Javascript/Node being single-threaded does not mean it needs to wait for a request to be done, and also doesn't mean that it doesn't use threads internally.`}</p>
    <h1>{`Non-blocking I/O`}</h1>
    <p>{`Since JavaScript execution in Node.js is single-threaded, it is possible to start an I/O process, but not block non-dependent tasks, it means that it can just start the communication, and continue to process other tasks that don’t need the I/O response, if a task needs the response, it will wait for it and then execute a callback. It helps parallel execution and the use of resources.`}</p>
    <h2>{`How is it done?`}</h2>
    <p>{`Instead of waiting for the process to complete, it sends it to a “worker”, and frees the thread, while it is processing the data, the thread can process another task.`}</p>
    <h2>{`Examples`}</h2>
    <h3>{`Blocking`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const content = fs.readFileSync('path'); // 1

console.log(content); // 2
console.log('Executed'); // 3

// The file content
// Executed
`}</code></pre>
    <p>{`The last `}<inlineCode parentName="p">{`console.log`}</inlineCode>{` is totally independent of the file read operation, it doesn't need its result, but even then, it needs to wait for it to complete before executing.`}</p>
    <h3>{`Non-blocking`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fs.readFile('path', (content) => {
  console.log(content);
});

console.log('Executed');

// Executed
// The file content
`}</code></pre>
    <p>{`In this case, the last `}<inlineCode parentName="p">{`console.log`}</inlineCode>{` is executed first, which is great, because again, it doesn't need the file read result, so it doesn't make sense to wait for it.
This is done because Node throws the process to the "worker", and while it is doing its work, Node goes to execute the second task, which is the `}<inlineCode parentName="p">{`console.log('Executed')`}</inlineCode>{`.`}</p>
    <h1>{`Libuv`}</h1>
    <p>{`Libuv is an open-source library written in C, that uses a thread-pool (multi-thread) to manage parallel operations.`}</p>
    <p>{`So, what are those `}<em parentName="p">{`workers`}</em>{` I have been talking about? Well, they are part of Libuv.
Workers are non-blocking async I/O processes in the background managed by Libuv. Each sync I/O operation they receive from the event loop is run on a background thread, to not block the main thread.`}</p>
    <p>{`Libuv also provides the `}<strong parentName="p">{`event loop`}</strong>{`, where it was made to be tied with only a single thread. You can run multiple event loops, but it must be one per thread.
Inside Libuv, there are a lot of concepts, like handles, who are abstractions to some resources like TCP/UDP and signals. There are also handles to interact with the event loop, like `}<inlineCode parentName="p">{`idle`}</inlineCode>{`, `}<inlineCode parentName="p">{`prepare`}</inlineCode>{`, `}<inlineCode parentName="p">{`check`}</inlineCode>{`, and some async types. Each one serves to do a specific action with the event loop, such as run something before or after Libuv does I/O polling for example.`}</p>
    <p>{`Also, some modules from Node comes from Libuv, like the `}<inlineCode parentName="p">{`fs`}</inlineCode>{` and `}<inlineCode parentName="p">{`child_process`}</inlineCode>{` module.`}</p>
    <h3>{`Async File I/O`}</h3>
    <p>{`When a task for something like a file I/O is put in the call stack, the event loop sends it to the Libuv thread pool, when the data is processed, it puts it in the `}<strong parentName="p">{`Task Queue`}</strong>{`, for the event loop to call when it is available.`}</p>
    <h3>{`Network I/O`}</h3>
    <p>{`The thread pool is bypassed completely when executing low-level OS operations, like a REST API call. Libuv nor node handle these. Instead, there’s something called OS Async Helpers, where the operations are executed immediately as soon as a CPU is available.`}</p>
    <p>{`After the task is done, it puts the callback into the `}<strong parentName="p">{`Job Queue`}</strong>{`, for the event loop to call after the call stack is empty.`}</p>
    <p>{`All network I/O are performed on sockets which are polled using epoll/kqueue/IOCP (system event interfaces).`}</p>
    <h1>{`Event Loop`}</h1>
    <p>{`We can think about the event loop like someone who sends tasks to the right places, for example, if it gets a task, it will get it from the call stack, see if it can be run on the system primitive (kqueue, epoll), if yes, it will send to it and them get back the response after it’s done, if not, it will send to a background worker. So it’s literally just “communication”.`}</p>
    <p>{`But in the middle of all that communication, it also manages a lot of other stuff, like timers, and other callbacks.`}</p>
    <p>{`The event loop is what allows Node.js to perform non-blocking I/O operations, by offloading operations to the system kernel whenever possible. The event loop will go through a phase, execute the callbacks related to that phase, and then move to the next one. Each phase has a callback queue. Depending on the task, it will send it to the corresponding queue.`}</p>
    <p>{`When Node.js starts, it initializes the event loop, processes the script, then begins processing the event loop.
The loop only stops when there are no more tasks queued in the event loop or in the call stack.`}</p>
    <h2>{`Phases`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "308px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "183.72093023255815%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAlCAYAAABCr8kFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC5UlEQVRIx62WaW4iQQyFuQR72JKwBAgJO0GsIQgJCIL7H8ajz9IjpdFoplqaH6aq3N2vvD6TKhQK9v7+btPp1MbjsY1GIz8PBgObTCauYy2Xy1YsFu3h4eGvkgKw2+3ay8uLNRoNB1iv1w7SbDZder2eVSqVeMDX11drt9v+MdZtNhv7+PiwVqvl8vb2lhwQV3ETQCwChDMyHA6tVCrFAfIS8Xl8fHSp1WoOVq/Xff/09GTVajUKzAH5wcpcLmf5fN6y2ay7DgA66QX4L2B3mWTgKpbhLgkJXe90OlFgd0A+JI58SHIQ9jr3+/34pBCr/X7vpXI8Hu18Pttut7PL5WKn08k+Pz/d2ug6JOh8gDVYGbrKXrpoC8kgtSYglRCA7LVGxxALQwUfYS0WhbroLFN7xAdghLPqkD26WHfvLtPLuMyK4DqrXOZZdKdgBQCQA21G/Gg1SIJz4l6mvbCE7lgul14mi8XCS2a73TpgIrbBZbmFmyQkXEUS0XWowOMyXcGK2wBKR4KikwIg7ZfJZJwY0um0A0AI6BAIQiWDXsJ3iM68d7dQPYxVJCW0kPgCSKaJqXSQCpezV/+nxH8QACvCQxIhBuIZFvAul/F8Pp970g6HgyeTi9B5Usgy1nATt/ASIJyfn599JFBGPGOYrVYr+/7+tuv1arfbzSvi6+vLZrPZTx2KqljhQ3ScuRkwgBH2qlEuhqnwhBnkFqoOVdDI73usJfh4ozGr0YtVPMcAB8RCsscHyiLukwDpQmIIy0dZDknkzjYULgIQBY017NHFUte9U0Tzyiquyi3xZDQfEsMwASHb4LrIN7qXiaEGFGVCkCkJZgxggGJ1dC/jsiwKJ11IEommHi7TQprJWEsdapYoHIlmyn/954CFuPOnmSJdov82yrJGp8hWs5n48Sx6ptCfAoA16ElGAEzCqraKTgo3K/D0IoCUDmUDIFaS7WhAgg5z8H8GC6EmBDDOUBf7WJd/AZtHIaX88fVgAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Event Loop",
          "title": "Event loop",
          "src": "/static/917f40400cc849d71c917705d15cfea7/2ece4/event_loop_stages.png",
          "srcSet": ["/static/917f40400cc849d71c917705d15cfea7/d9489/event_loop_stages.png 258w", "/static/917f40400cc849d71c917705d15cfea7/2ece4/event_loop_stages.png 308w"],
          "sizes": "(max-width: 308px) 100vw, 308px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`timers:`}</strong>{` Executes callbacks scheduled by setTimeout() and setInterval().`}</p>
    <p><strong parentName="p">{`pending callbacks:`}</strong>{` Executes callbacks for some system operations.`}</p>
    <p><strong parentName="p">{`idle, prepare:`}</strong>{` Libuv handles, idle will run the given callbacks once per loop iteration, prepare runs before libuv does its I/O polling.`}</p>
    <p><strong parentName="p">{`poll:`}</strong>{` Retrieve new I/O events and execute I/O related callbacks;`}</p>
    <p><strong parentName="p">{`check:`}</strong>{` Execute setImmediate() callbacks.`}</p>
    <p><strong parentName="p">{`close callbacks:`}</strong>{` Execute close callbacks, e.g. socket.on('close', ...).`}</p>
    <h2>{`Final diagram`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "811px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.627906976744185%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABd0lEQVQoz3WT646CMBCFeQwRbyCCKArKRY0iiT95//eZzTfJdLub+GPSTttzeuZMG1wuFzmfz3I6nWS5XMrxeHR5lmUSRZGEYSi73U5ut5tcr1dpmkbSNJXNZiNxHMtisdBzjEHf9/J4PPQgBEVRuAC0Wq0URP58PjWqqpLtdqvrBGccIUpQdTgcJEkSHVFQ17USrtdrBaCGcxDv93uthj0wfwhJTAkHAHBJWZbuMOsAIGGNERsg4CKzZTabSTCfz3WRTcBd18nn85HX66WkkBHsUyYXkqOybVuthj6gHkwAOz7YrTQEwvf7rV7RDMjYZw4QAVgDEdbgP5eP4/hLCMgCpZQNgDHPc1eSXWy5zV3JPiFzAp9Yo0TmdB91hDXP9nwh2hSfED8B4SOlAMKraZpkGAb17H6/qy2USPkotg5/JcQT/61hPDkE9sQYscJ/1I7QWg8hIwDKxEtfIcR8AvtJXxXiBaQ8B/PJCFFIDjFrvof/vx3xA8EZVydOQgayAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Event Loop Complete",
          "title": "Event loop complete diagram",
          "src": "/static/348dfe38972583236eddee6b30f3a6d5/fd28b/event_loop_complete.png",
          "srcSet": ["/static/348dfe38972583236eddee6b30f3a6d5/d9489/event_loop_complete.png 258w", "/static/348dfe38972583236eddee6b30f3a6d5/75dcb/event_loop_complete.png 516w", "/static/348dfe38972583236eddee6b30f3a6d5/fd28b/event_loop_complete.png 811w"],
          "sizes": "(max-width: 811px) 100vw, 811px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Quick recap`}</h3>
    <p>{`Looking in that diagram, I will follow the readFile task.`}</p>
    <ol>
      <li parentName="ol">{`A read file task is placed on the call stack.`}</li>
      <li parentName="ol">{`The event loop sends it to Libuv background threads.`}</li>
      <li parentName="ol">{`While the threads are working, the event-loop single-thread can process another task since it's empty.`}</li>
      <li parentName="ol">{`After the Libuv threads are done processing the readFile callback, it sends it to the respective queue, in this case, the Task Queue.`}</li>
      <li parentName="ol">{`After the call stack is empty, the event-loop grabs the readFile callback from the Task Queue and place it on the call stack to be executed.`}</li>
    </ol>
    <p>{`Now, the API request example.`}</p>
    <ol>
      <li parentName="ol">{`An API request task is placed on the call stack.`}</li>
      <li parentName="ol">{`The event loop sends it to the OS to process.`}</li>
      <li parentName="ol">{`While the OS is working, the event-loop single-thread can process another task since it's empty.`}</li>
      <li parentName="ol">{`After the OS is done processing, it sends it to the respective queue, in this case, the Job Queue.`}</li>
      <li parentName="ol">{`After the call stack is empty, the event-loop grabs the API request callback from the Job Queue and place it on the call stack to be executed.`}</li>
    </ol>
    <h1>{`Generators`}</h1>
    <p>{`This is more like extra content, but generators also have a pretty good part inside Node.
But first, let's see about Iterators.`}</p>
    <h2>{`Iterators`}</h2>
    <p>{`An iterator is an object that produces a sequence of values and return a value on its end.`}</p>
    <h3>{`What is defined as an iterator`}</h3>
    <p>{`An object to be defined as an iterator needs to implement a `}<inlineCode parentName="p">{`next`}</inlineCode>{` method, which needs to return an object containing two properties:`}</p>
    <p><strong parentName="p">{`Done:`}</strong>{` Defines if the sequence is finished or not`}</p>
    <p><strong parentName="p">{`Value:`}</strong>{` Any value returned by the iterator`}</p>
    <p>{`An iterator object can be iterated by calling the `}<inlineCode parentName="p">{`next`}</inlineCode>{` method. When all the values are iterated, the `}<inlineCode parentName="p">{`done`}</inlineCode>{` property returns true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const iterator = [1, 2, 3][Symbol.iterator]();
iterator.next(); // { value: 1, done: false }
iterator.next(); // { value: 2, done: false }
// ...
iterator.next(); // { value: undefined, done: true }
`}</code></pre>
    <h2>{`Creating an Iterator`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function makeRangeIterator(start = 0, end = Infinity) {
  let nextIndex = start;
  let iterationCount = 0;

  const rangeIterator = {
    next: function () {
      let result;
      if (nextIndex < end) {
        result = { value: nextIndex, done: false };
        nextIndex++;
        iterationCount++;
        return result;
      }
      return { value: iterationCount, done: false };
    },
  };
  return rangeIterator;
}
`}</code></pre>
    <p>{`That'll seem like too much code, but it's pretty straightforward.
The function implements and returns the `}<inlineCode parentName="p">{`next`}</inlineCode>{` function, in which when you call it, it will increment the current index, and return the result, which it's the `}<inlineCode parentName="p">{`value`}</inlineCode>{` and `}<inlineCode parentName="p">{`done`}</inlineCode>{` object. When it's the final index, we just return the `}<inlineCode parentName="p">{`done: true`}</inlineCode>{`. In this example, I used just the iteration count for the value.`}</p>
    <p>{`This implementation is considered an Iterator since it has all the requirements.`}</p>
    <h2>{`Now back to generators`}</h2>
    <p>{`Generators are a type of function that can be executed, paused, and resumed in a controlled manner.
There are two main things that generators are used for, implementing lazy iterators and blocking asynchronous calls.`}</p>
    <p>{`Here is the same function above, but implemented using generators.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function* makeRangeIterator(start = 0, end = Infinity) {
  let iterationCount = 0;

  for (let i = start; i < end; i++) {
    iterationCount++;
    yield i;
  }

  return iterationCount;
}
`}</code></pre>
    <p>{`Very noticeable better. Generators have that special syntax that you need to add an `}<inlineCode parentName="p">{`*`}</inlineCode>{` after the `}<inlineCode parentName="p">{`function`}</inlineCode>{` keyword, this determines that this function is a generator.
Besides that, the only other weird thing is the `}<inlineCode parentName="p">{`yield`}</inlineCode>{` keyword. When the code is being executed, it will stop right when it reaches the `}<inlineCode parentName="p">{`yield`}</inlineCode>{`, and the value after the `}<inlineCode parentName="p">{`yield`}</inlineCode>{`, in this case, the `}<inlineCode parentName="p">{`i`}</inlineCode>{`, is the value that will be returned in the `}<inlineCode parentName="p">{`value`}</inlineCode>{` property from that iteration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const generator = makeRangeIterator();
generator.next(); // { value: 0, done: false }
generator.next(); // { value: 1, done: false }
generator.next(); // { value: 2, done: false }
// ...
`}</code></pre>
    <p>{`You can also inject a value by passing the value to the `}<inlineCode parentName="p">{`next`}</inlineCode>{` function, like so, `}<inlineCode parentName="p">{`generator.next('new value')`}</inlineCode>{`.`}</p>
    <h1>{`Async Await`}</h1>
    <p>{`Have you ever wondered how this thing works? Me neither, but I will show you.`}</p>
    <p>{`Async Await is a way to pause the execution of a code on the `}<inlineCode parentName="p">{`await`}</inlineCode>{` keyword, when used on a promise, and resume only when the promise is resolved.`}</p>
    <p><strong parentName="p">{`So we need 3 things to make something like this work.`}</strong></p>
    <ol>
      <li parentName="ol">{`Pause a function`}</li>
      <li parentName="ol">{`Put a value inside the function`}</li>
      <li parentName="ol">{`Resume a function`}</li>
    </ol>
    <p>{`And that's exactly what generators allowed us to do!`}</p>
    <p>{`So, let's implement our own Async Await, why not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const wrapToReturnPromise = function (gen) {
  const generator = gen();
  const { value } = generator.next();

  if (value.isPromise()) { // pseudo-code
    return value
      .then((val) => generator.next(val))
      .catch((err) => generator.throw(err));
  }

  // Error if not promise
};
`}</code></pre>
    <p>{`Here we created a function that receives a generator function from its parameters, then we call the generator `}<inlineCode parentName="p">{`next`}</inlineCode>{` method to get its initial value, after that, we check if that value is a promise and we call `}<inlineCode parentName="p">{`next`}</inlineCode>{` again inside the `}<inlineCode parentName="p">{`then`}</inlineCode>{`, but this time injecting the value to it (`}<inlineCode parentName="p">{`generator.next(val)`}</inlineCode>{`), which would cause it to be returned as `}<inlineCode parentName="p">{`{value: val, done}`}</inlineCode>{` object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const fakeApi = function () {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve('Fake data');
    }, 1000);
  });
};

const foo = wrapToReturnPromise(function* () {
  try {
    const val = yield fakeApi();
    console.log(val); // "Fake data"
    return val;
  } catch (err) {
    console.log('Error: ', err.message);
  }
});
`}</code></pre>
    <p>{`Here is the interesting part, we call the `}<inlineCode parentName="p">{`wrapToReturnPromise`}</inlineCode>{`, passing in our generator function, which in this case just calls a function that returns a Promise and logs it, but we can see that it behaves exactly like an async await function, the `}<inlineCode parentName="p">{`yield`}</inlineCode>{` will tell the runtime to stop there, just like the await, and after the promise is resolved, it resumes and logs the returned value!. Very cool (for me at least).`}</p>
    <p>{`And here's where I'm gonna end this post, I hope it was useful for you and you learned something. This stuff may be difficult to understand sometimes, but time will take care of it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      